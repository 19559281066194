export const YourQuoteSchema = {
  vehicleAmountData: [
    {
      label: 'Up to 50',
      value: 'false',
    },
    {
      label: '51 or more',
      value: 'true',
    },
  ],
  breakDownCoverLocationData: [
    {
      label: 'The UK',
      value: 'THE UK',
    },
    {
      label: 'The UK and Europe',
      value: 'THE UK AND EUROPE',
    },
  ],
  vehicleRepairLocationData: [
    {
      label: 'Include national recovery',
      value: 'ANYWHERE IN THE UK',
    },
    {
      label: 'No thanks',
      value: 'THE NEAREST GARAGE',
    },
  ],
  pleaseGiveUsACallModalData: {
    heading: 'Please give us a call.',
    body: {
      text: "To get cover for 51 or more vehicles, we'll need to chat over the phone.",
      number: 'Call 0345 246 0637.',
      text2: 'Call charges will vary.',
      Openinghours: 'Opening hours:',
      OpeninghoursTime: {
        monFri: 'Mon-Fri: 8am - 9:15pm',
        sat: 'Saturday: 9am - 5pm',
        sun: ' Sunday: 10am - 5pm',
      },
    },
  },
};

export default YourQuoteSchema;
