import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  ...theme.layouts.stepGrid,
  businessDetailsContainer: {
    marginBottom: '2rem',
    '& h2': {
      marginBottom: '2rem',
    },
  },
  px05: {
    paddingTop: '.5rem',
    paddingBottom: '.5rem',
  },
  fw500: {
    fontWeight: 500,
  },
  businessHeadersPadding: {
    marginTop: '3rem',
    paddingBottom: '1.5rem'

  }
}));
