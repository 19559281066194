import React, { Dispatch, SetStateAction, useState, useContext } from 'react';
import { useStyles } from './EmailMeThisQuoteModalBodyStyle';
import { MuModal, Typography, TextField, ButtonPrimary } from '../../atoms';
import { Box } from '@material-ui/core';
import { ReCAPTCHA } from '../ReCAPTCHA';
import { useForm } from 'react-hook-form';
import EmailModalSchema from './EmailMeThisQuoteModalSchema.json';
import { IStepData, StepContext } from '../../../contexts/StepContext';
import * as yup from 'yup';
import { format } from 'date-fns';
import { yupResolver } from '@hookform/resolvers/yup';
import clsx from 'clsx';
import axios from 'axios';

interface IEmailModal {
  formData: any;
  localStateValues: IStepData;
  emailMeThisQuoteModalActive: boolean;
  onCancelClick: (emailMeThisQuoteModalActive: boolean) => void;
  setEmailAddress: Dispatch<SetStateAction<string>>;
}

const EmailMeThisQuoteModalBody: React.FC<IEmailModal> = ({
  emailMeThisQuoteModalActive,
  onCancelClick,
  formData,
  setEmailAddress,
  localStateValues,
}: IEmailModal): JSX.Element => {
  const { updateData, data } = useContext(StepContext);
  const classes = useStyles();
  const [submitting, setSubmitting] = useState(false);

  const [reCaptchaToken, setRecaptchaToken] = useState('');
  const [emailMeThankYouModalActive, setEmailMeThankYouModelActive] = useState(false);

  const schema = yup.object().shape({
    emailAddress: yup.string().email('Invalid Email.').required('Invalid Email.'),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    formState,
  } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
  });

  const onSubmit = async ({ emailAddress }): Promise<void> => {
    if (!submitting) {
      setSubmitting(true);

      try {
        const coverOptionsData: string[] = [];
        if (formData?.Items) {
          formData?.Items.map((element) => {
            if (element.coverType === localStateValues.coverType) {
              element['price'] = localStateValues.quoteTotal;
              element['number'] = localStateValues.vehicleCount;
            } else {
              element['price'] = 0;
              element['number'] = 0;
            }
            return coverOptionsData.push(element);
          });
        } else {
          formData.map((element) => {
            if (element.coverType === localStateValues.coverType) {
              element['price'] = localStateValues.quoteTotal;
              element['number'] = localStateValues.vehicleCount;
            } else {
              element['price'] = 0;
              element['number'] = 0;
            }
            return coverOptionsData.push(element);
          });
        }

        const Quote = {
          moreThanFiftyVehicles: localStateValues.moreThanFiftyVehicles,
          breakdownCoverLocation: localStateValues.breakdownCoverLocation,
          vehicleRepairLocation: localStateValues.vehicleRepairLocation,
          coverType: localStateValues.coverType,
          vehicleCount: localStateValues.vehicleCount,
          quoteTotal: localStateValues.quoteTotal,
          coverOptions: coverOptionsData,
          partnerizeRef: data.partnerizeRef,
          startDate:
            localStateValues.coverStartDate !== null
              ? format(new Date(localStateValues.coverStartDate), 'yyyy/MM/dd')
              : null,
          emailAddress: emailAddress,
          captchaValue: reCaptchaToken,
        };

        await axios.post(`${process.env.REACT_APP_SERVERLESS_BASE_URL}/${process.env.REACT_APP_QUOTE_POST_ENDPOINT}`, {
          Quote,
        });
        setEmailAddress(emailAddress);

        updateData({
          ...data,
          emailAddress: emailAddress,
          partnerizeRef: data.partnerizeRef,
        });
        onCancelClick(false);
        setEmailMeThankYouModelActive(true);
      } catch (err) {
        console.error(err);
      } finally {
        setSubmitting(false);
      }
    }
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    try {
      const targetValue = e?.target?.value.trim();
      e.target.value = targetValue;
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <MuModal
        isOpen={emailMeThisQuoteModalActive}
        onCloseClick={() => onCancelClick(false)}
        ariaLabelledBy={'Email notification modal'}
        isSmallClose={true}
        isBorderStyle={true}
      >
        <Typography variant="h2">{EmailModalSchema.emailNotificationModalData.heading}</Typography>
        <Typography className={classes.modalPText}>{EmailModalSchema.emailNotificationModalData.body.text}</Typography>
        <Box>
          <TextField
            className={clsx(classes.inputFields, 'pt1')}
            id="emailAddress"
            name="emailAddress"
            control={control}
            defaultValue={data.emailAddress}
            type="email"
            error={errors.emailAddress ? true : false}
            onBlur={handleBlur}
          />
          {errors.emailAddress && <Typography className="fieldError">{errors.emailAddress.message}</Typography>}
        </Box>

        <div className={classes.recaptchaContainer}>
          <ReCAPTCHA reCaptchaToken={reCaptchaToken} setRecaptchaToken={setRecaptchaToken} />
        </div>
        <ButtonPrimary
          disabled={!formState.isValid || reCaptchaToken === ''}
          className="mt2"
          onClick={handleSubmit(onSubmit)}
        >
          {EmailModalSchema.emailNotificationModalData.body.buttonText}
        </ButtonPrimary>
      </MuModal>
      <MuModal
        isOpen={emailMeThankYouModalActive}
        onCloseClick={() => setEmailMeThankYouModelActive(false)}
        ariaLabelledBy={'Email notification modal'}
        isSmallClose={false}
        isBorderStyle={true}
      >
        <Typography variant="h2">{EmailModalSchema.emailThankYouModalData.heading}</Typography>
        <Typography className={classes.modalPText}>{EmailModalSchema.emailThankYouModalData.body.text}</Typography>
        <Typography className={classes.modalPText}>{EmailModalSchema.emailThankYouModalData.body.text2}</Typography>
        <ButtonPrimary onClick={() => setEmailMeThankYouModelActive(false)}>
          {EmailModalSchema.emailThankYouModalData.body.buttonText}
        </ButtonPrimary>
      </MuModal>
    </>
  );
};

export default EmailMeThisQuoteModalBody;
