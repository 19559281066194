import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  inputFields: {
    maxWidth: '20rem',
    width: '100%',
    minHeight: '3rem',
  },

  modalPText: {
    margin: '1rem 0 1rem 0',
  },
  recaptchaContainer: {
    marginTop: '1rem',
  }}),
  { index: 1 },
);
