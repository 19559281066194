import React, { useState, useEffect } from 'react';
import { useStyles } from './YourCoverStyles';
import { Grid, IconButton } from '@material-ui/core';
import { Typography, Tooltip } from '..';
import CheckIcon from '@material-ui/icons/Check';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import InfoIcon from '@material-ui/icons/Info';
import yourCoverSchema from './YourCoverSchema.json';
import clsx from 'clsx';

interface ICoverKeyPoints {
  title: string;
  content: string;
  contentSub?: string;
}

interface IYourCover {
  coverPrice: number | null;
  coverType: string;
  numberOfVehicles: number | null | undefined;
}

export const YourCover: React.FC<IYourCover> = ({
  coverType,
  numberOfVehicles,
  coverPrice,
}: IYourCover): JSX.Element => {
  const [tooltipOpenIndex, setTooltipOpenIndex] = useState<number | null>(null);
  const [coverTypeDetails, setCoverTypeDetails] = useState<any>();
  const { coverKeyPoints, Items } = yourCoverSchema;
  const classes = useStyles();

  const getCoverTypeDetails = (coverType: string) => {
    const coverTypeDetailsData = Items.find((item) => item.coverType === coverType);
    setCoverTypeDetails(coverTypeDetailsData);
  };

  useEffect(() => {
    getCoverTypeDetails(coverType);
  }, [coverType]);

  const handleTooltipClose = () => {
    setTooltipOpenIndex(null);
  };

  const handleTooltipOpen = (index: number) => {
    setTooltipOpenIndex(index);
  };
  const yourQuoteValueDescription = coverKeyPoints.filter((item) =>
    coverTypeDetails?.coverDescription.values.includes(item.title),
  );

  return (
    <div className={classes.yourCoverContainer}>
      <div className={classes.yourCoverSummaryBackground}>
        <div className="pb2 pt1">
          <Typography className={classes.headings} variant="h2">
            The cover level that best suits your needs is our{' '}
            <span className={classes.greenHighlight}>{coverType ? coverType : ''}</span> cover.
          </Typography>
        </div>
        <div className="pb2 pt1">
          <Typography className={classes.headings} variant="h2">
            For <span className={classes.greenHighlight}>{numberOfVehicles ? numberOfVehicles : 'X'}</span> vehicle(s), this
            will cost <span className={classes.greenHighlight}>£{coverPrice ? coverPrice.toFixed(2) : 'XXX.XX'}</span> for the
            year.
          </Typography>
        </div>
      </div>
      <div className={classes.yourCoverBackground}>
        <Grid container>
          {yourQuoteValueDescription.map((k: ICoverKeyPoints, index: number) => (
            <Grid key={index} item xs={12} sm={6} className={classes.yourCoverGridItem}>
              <CheckIcon className={clsx(classes.checkIcon, index % 2 ? classes.secondGridColumn : '')} />
              <Typography className={classes.coverKeyPoint}>{k.title}</Typography>

              <Tooltip
                open={index === tooltipOpenIndex}
                onClose={handleTooltipClose}
                onClick={() => handleTooltipOpen(index)}
                title={
                  k.contentSub ? (
                    <>
                      <Typography variant="body2">{k.content}</Typography>
                      <Typography variant="body2">{k.contentSub}</Typography>
                    </>
                  ) : (
                    <Typography variant="body2">{k.content}</Typography>
                  )
                }
                placement={index % 2 ? 'bottom-end' : 'bottom-start'}
                arrow
              >
                <IconButton className={classes.infoIconButton}>
                  {tooltipOpenIndex === index ? (
                    <InfoIcon className={classes.infoIconHighlight} />
                  ) : (
                    <InfoOutlinedIcon className={classes.infoIcon} />
                  )}
                </IconButton>
              </Tooltip>
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  );
};

export default YourCover;
