import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  vehicleContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '1rem',
    '@media (max-width: 750px)': {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  vehicleBtnOptions: {
    marginLeft: '1rem',
    '@media (max-width: 750px)': {
      marginTop: '0.75rem',
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      marginLeft: 0,
    },
  },
  vehicleTextField: {
    marginLeft: '1rem',
    '& input': {
      textTransform: 'uppercase',
    },
  },
  
  registrationNumberLabel: {
    ...theme.form.labels.inputField.label,
    color: theme.palette.dark[800],
    marginBottom: '1rem'
  },
  inputWidth: {
    minWidth: '14rem',
  },
  vehicleLookUpContainer: {
    border: `2px solid ${theme.palette.primary.dark}`,
    borderRadius: '5px',
    maxWidth: '40rem',
    marginBottom: '1rem',
  },
  vehicleLookUpGrid: {
    padding: '1rem 1.75rem',
    '@media (max-width: 767px)': {
      padding: '1rem 1rem',
    },
  },
  bottomHighlight: {
    color: theme.palette.common.black,
    padding: '0rem 1.25rem',
    backgroundColor: '#F8F9FA',
  },
  lookupContainer: {
    display: 'flex',
    '@media (max-width: 500px)': {
      flexDirection: 'column'
    }
  },
  lookupBtn: {
    marginLeft: '2rem',
    minWidth: '17rem',
    '@media (max-width: 500px)': {
      marginTop: '1rem',
      marginLeft: '0rem',
    }
  },
  responsivePadding: {
    '@media (max-width: 767px)': {
      paddingTop: '1rem'
    }
  },
  regBoxContainer: {
    display: 'flex',
    paddingRight: '1rem',
  },
  regBox: {
    minWidth: '7rem',
    padding: '1rem',
    paddingRight: '1rem',
    border: `2px solid ${theme.palette.primary.dark}`,
    borderRadius: '5px',
  },
  maxWidth30: {
    maxWidth: '30rem'
  },
  regNumberTitle: {
    fontWeight: 500,
    padding: '1rem 0rem .25rem 0rem'
  },
}));
