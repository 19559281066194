import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.dark[900],
    width: '100%',
    paddingBottom: '2rem',
    borderBottom: `8px solid ${theme.palette.green.main}`,
  },
  linksBarBg: {
    backgroundColor: theme.palette.dark[800],
  },
  linksBarContainer: {
    maxWidth: '1280px',
  },
  linksBar: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '1rem 0.5rem',
    '@media (max-width: 700px)': {
      justifyContent: 'flex-start',
    },
    '@media (max-width: 350px)': {
      '& ul': {
        flexDirection: 'column',
        padding: 0,
      },
    },
    '& ul': {
      display: 'flex',
      listStyleType: 'none',
      padding: 0,
    },
    '& li': {
      marginLeft: '2.5rem',
      ...theme.leads.lead2,
      lineHeight: '16px',
      '@media (max-width: 700px)': {
        marginLeft: 0,
        marginRight: '2rem',
      },
      '@media (max-width: 350px)': {
        marginLeft: 0,
        marginBottom: '1.5rem',
        '&:last-child': {
          marginBottom: 0,
        },
      },
    },
    '& a': {
      color: theme.palette.light.main,
      textDecoration: 'none',
      '&:hover': {
        cursor: 'pointer',
      },
    },
  },

  // Styling for mobile link bar specific
  linksBarMobile: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '1rem 0.5rem',
    '@media (max-width: 430px)': {
      padding: '0rem .5rem',
    },
    '@media (max-width: 700px)': {
      justifyContent: 'flex-start',
    },
    '@media (max-width: 350px)': {
      '& ul': {
        flexDirection: 'column',
        padding: 0,
      },
    },
    '& ul': {
      display: 'flex',
      listStyleType: 'none',
      padding: 0,
    },
    '& li': {
      marginLeft: '2.5rem',
      ...theme.leads.lead2,
      lineHeight: '16px',
      '@media (max-width: 700px)': {
        marginLeft: 0,
        marginRight: '2rem',
      },
      '@media (max-width: 350px)': {
        marginLeft: 0,
        marginBottom: '1.5rem',
        '&:last-child': {
          marginBottom: 0,
        },
      },
    },
    '& a': {
      color: theme.palette.light.main,
      textDecoration: 'none',
      '&:hover': {
        cursor: 'pointer',
      },
    },
  },

  greenFlagLogoContainer: {
    display: 'flex',
    alignItems: 'start',
  },
  footerTextContainer: {
    paddingLeft: '1rem',
    color: theme.palette.light.main,
    '& a': {
      color: theme.palette.light.main,
      '&:hover': {
        cursor: 'pointer',
      },
    },
    '@media (max-width: 1100px)': {
      paddingLeft: '4rem',
    },
    '@media (max-width: 959px)': {
      paddingLeft: 0,
      marginTop: '1rem',
    },
  },
  textHover: {
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  container: {
    maxWidth: '1280px',
    paddingTop: '1.5rem',
    paddingBottom: '1rem',
    paddingLeft: '1.5rem',
  },
  gridContainer: {
    marginTop: '1rem',
  },
  greenFlagLogo: {
    width: '57px',
  },
  copyrightText: {
    marginTop: '1rem',
    paddingLeft: '1rem',
    color: theme.palette.light.main,
    '@media (max-width: 1100px)': {
      paddingLeft: '4rem',
    },
    '@media (max-width: 959px)': {
      paddingLeft: 0,
    },
    '@media (max-width: 350px)': {
      marginBottom: '1.25rem',
    },
  },
  manageCookiesBtn: {
    fontFamily: ['Oswald-Regular', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    color: theme.palette.green[600],
    backgroundColor: theme.palette.light[300],
    border: `1px solid ${theme.palette.green[600]}`,
    height: 'auto',
    whiteSpace: 'normal',
    wordWrap: 'break-word',
    padding: '0.8em 2em',
    lineHeight: 1.2,
    mozTransition: '0.1s ease',
    oTransition: '0.1s ease',
    webkitTransition: '1s ease',
    transition: '0.1s ease',
    fontWeight: 700,
    '&:hover': {
      color: theme.palette.light[300],
      backgroundColor: theme.palette.green[600],
      cursor: 'pointer',
    },
    '&:focus': {
      outline: '5px auto -webkit-focus-ring-color',
    },
  },
  minWidth35: {
    minWidth: '35rem',
  },
  desktopView: {
    '@media (max-width: 430px)': {
      display: 'none',
    },
  },
  mobileView: {
    '@media (min-width: 431px)': {
      display: 'none',
    },
  },
}));
