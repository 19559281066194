import * as yup from 'yup';

export const styleGuideYupSchema = yup.object().shape({
  radioBtnTest: yup.string().required('Radio Button is required.'),
  textFieldTest: yup.string().required('TextField is required.'),
  textFieldMinMaxTest: yup
    .number()
    .min(1, 'Must be a number between 1 and 10.')
    .max(10, 'Must be a number between 1 and 10.'),
  checkboxTest: yup.bool().oneOf([true], 'Checkbox must be checked.'),
  dropdownTest: yup.string().required('Dropdown is required.'),
  datePickerTest: yup.date().required('DatePicker is required.').nullable(),
  vehicleCount: yup.number().min(0).max(999).required(),
  vehicleCost: yup.number().required(),
  vehicleId: yup.string().required(),
  vehicleLookup: yup
    .array()
    .of(
      yup.object().shape({
        reactIndex: yup.number(),
        reg: yup.string().required(),
        make: yup.string().required(),
        model: yup.string().optional().nullable(),
        transmissionType: yup.string().nullable(),
        fuelType: yup.string().nullable(),
        year: yup.string().nullable(),
        complete: yup.bool().oneOf([true]).required(),
      }),
    )
    .min(1)
    .required(),
  postcodeLookup: yup.object().shape({
    firstLineOfAddress: yup.string().required('First Line of Address is required.'),
    secondLineOfAddress: yup.string(),
    town: yup.string().required('Town is required.'),
    county: yup.string(),
    postcode: yup.string().required('Postcode is required.'),
  }),
});

export default styleGuideYupSchema;
