import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    ...theme.layouts.stepGrid,
    nextStepBtnContainer: {
      margin: '1rem 1rem 0.5rem 1rem',
      '& button': {
        marginRight: '1.5rem',
      },
    },
    btnContainer: {
      margin: '1rem',
    },
    btn: {
      marginRight: '1rem',
    },
    error: {
      color: theme.palette.error.main,
    },
    inputLabel: {
      marginBottom: '0.5rem',
    },
    textFieldContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '10rem',
      marginTop: '0.5rem',
      marginRight: '1rem',
    },
    checkboxContainer: {
      marginTop: '1rem',
    },
    formContainer: {
      marginTop: '1.5rem',
    },
    yourPriceContainer: {
      marginTop: '1rem',
    },
    datePickerContainer: {
      marginTop: '1rem',
      marginBottom: '1rem',
    },
    datePicker: {
      marginTop: '0.5rem',
    },
    genericContainer: {
      marginTop: '1rem',
    },
    postcodeLookupContainer: {
      margin: '1rem 0',
    },
    postcodeLookupContainerField: {
      width: '15rem',
      marginBottom: '1rem',
    },
    actionBtnsContainer: {
      marginTop: '0.75rem',
    },
    submitBtn: {
      marginRight: '1rem',
    },
    sticky: {
      position: 'sticky',
      display: 'block',
      top: '9rem',
    },
    dNoneMobile: {
      '@media (max-width: 425px)': {
        display: 'none',
      },
    },
    dOnlyMobile: {
      '@media (min-width: 426px)': {
        display: 'none',
      },
    },
    recaptcha: {
      margin: '1rem 0 3rem 0',
    },
    modalPText: {
      margin: '1rem 0 1rem 0',
    },
    contactSideMobile: {
      padding: '0 4rem 0 2rem',
      '@media (max-width: 1279px)': {
        padding: '1rem 0rem 0rem 0rem',
      },
    },
    linkContainer: {
      margin: '1.5rem 0',
      '& a': {
        marginRight: '1rem',
      },
    },
  }),
  { index: 1 },
);
