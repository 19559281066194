const MILLISECONDS_IN_AN_HOUR = 60 * 60 * 1000;

export const calculateEndDateForAnnual = (
  // eslint-disable-next-line
  startDateIn,
  logging = false,
): Date => {
  const newStartDate = new Date(new Date(startDateIn).getTime() + 12 * MILLISECONDS_IN_AN_HOUR);
  logging && console.log('Deriving Annual end date from start date: ', startDateIn);
  logging && console.log('Deriving Annual end date from newStartDate date: ', newStartDate);

  const year = newStartDate.getFullYear();
  //get month is zero-based. Parsing a date from a string expects a 1-based month
  const month = newStartDate.getMonth() + 1;
  const day = newStartDate.getDate();

  const plusOneYear = new Date(`${year + 1}-${month}-${day} 12:00:00`);

  const endDate = new Date(plusOneYear.getTime() - 24 * MILLISECONDS_IN_AN_HOUR);
  logging && console.log('Derived Annual end date : ', endDate);

  return endDate;
};

interface ICoverPrice {
  MinVehicles: number;
  MaxVehicles: number;
  Price: number;
  Name: string;
}

export interface IOption {
  coverType: string;
  coverDescription: { type: string; values: Array<string> };
  option_id: number;
  site_id: string;
  coverPrice: Array<ICoverPrice>;
}

export const calculateCostTotal = (data: IOption[], coverType: string, vehicleCount: any): number => {
  const findCoverPrice = (vehicleObject: IOption): number => {
    const filteredResult = vehicleObject.coverPrice.find(
      (v: ICoverPrice) => vehicleCount >= v.MinVehicles && vehicleCount <= v.MaxVehicles,
    );

    const result =
      filteredResult === undefined && vehicleCount <= 0
        ? 0
        : filteredResult === undefined &&
          vehicleCount >= vehicleObject.coverPrice[vehicleObject.coverPrice.length - 1].MaxVehicles
        ? vehicleObject.coverPrice[vehicleObject.coverPrice.length - 1].Price
        : filteredResult?.Price;

    return result === undefined ? 0 : result * vehicleCount;
  };

  let result = 0;

  if (data) {
    const vehicleObject = data.find((d: IOption) => d.coverType === coverType);

    if (vehicleObject !== undefined) {
      result = findCoverPrice(vehicleObject);
    }
  }

  return result;
};
