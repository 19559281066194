import { createMuiTheme } from '@material-ui/core';

const theme = createMuiTheme({
  props: {
    MuiButtonBase: {
      disableTouchRipple: true,
      focusVisibleClassName: 'focused-element',
    },
  },
  overrides: {
    MuiButton: {
      iconSizeMedium: {
        '& > *:first-child': {
          fontSize: '1.4rem',
        },
      },
    },
  },
  typography: {
    h1: {
      '@media (max-width: 767px)': {
        fontSize: '1.5rem', // 24px
        lineHeight: '1.75rem', // 28px
      },
      fontSize: '2rem', // 32px
      lineHeight: '2.188rem', // 35px
    },
    h2: {
      '@media (max-width: 767px)': {
        fontSize: '1.25rem', // 20px
        lineHeight: '1.5rem', // 24px
      },
      fontSize: '1.5rem', // 24px
      lineHeight: '1.75rem', // 28px
    },
    h3: {
      '@media (max-width: 767px)': {
        fontSize: '1.125rem', // 18px
        lineHeight: '1.375rem', // 22px
      },
      fontSize: '1.25rem', // 20px
      lineHeight: '1.5rem', // 24px
    },
    button: {
      fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    },
  },
  fontFamilies: {
    oswald: {
      fontFamily: ['Oswald-Regular', 'Helvetica', 'Arial', 'sans-serif'].join(','),
      fontWeight: 'normal',
      textTransform: 'uppercase',
    },
    roboto: {
      fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    },
  },
  leads: {
    lead1: {
      fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
      fontSize: '1.25rem',
      fontWeight: 500,
      letterSpacing: '0px',
      lineHeight: '1.75rem',
    },
    lead2: {
      fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
      fontSize: '1rem',
      fontWeight: 500,
      letterSpacing: '0px',
      lineHeight: '1.375rem',
    },
  },
  overlines: {
    overline1: {
      fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
      fontSize: '1rem',
      fontWeight: 500,
      letterSpacing: '0px',
      lineHeight: '1.188rem',
      textTransform: 'uppercase',
    },
    overline2: {
      fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
      fontSize: '0.875rem',
      fontWeight: 500,
      letterSpacing: '0px',
      lineHeight: '1.063rem',
      textTransform: 'uppercase',
    },
  },
  form: {
    headings: {
      small: {
        fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
        fontSize: '1.5rem',
        fontWeight: 500,
        letterSpacing: '0px',
        lineHeight: '1.75rem',
      },
      large: {
        fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
        fontSize: '2rem',
        fontWeight: 500,
        letterSpacing: '0px',
        lineHeight: '2.188rem',
      },
    },
    labels: {
      inputField: {
        label: {
          fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
          fontSize: '1.2rem',
          fontWeight: 500,
          letterSpacing: '0px',
          lineHeight: '1.375rem',
          color: '#1A1A1A',
        },
        hint: {
          fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
          fontSize: '1rem',
          fontWeight: 'normal',
          letterSpacing: '0px',
          lineHeight: '1.5rem',
        },
        moreInfo: {
          fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
          fontSize: '1rem',
          fontWeight: 'normal',
          letterSpacing: '0px',
          lineHeight: '1.25rem',
          textDecoration: 'underline',
        },
      },
      selectFieldSmall: {
        label: {
          fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
          fontSize: '1.25rem',
          fontWeight: 500,
          letterSpacing: '0px',
          lineHeight: '1.5rem',
        },
        hint: {
          fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
          fontSize: '1rem',
          fontWeight: 'normal',
          letterSpacing: '0px',
          lineHeight: '1.5rem',
        },
        moreInfo: {
          fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
          fontSize: '1rem',
          fontWeight: 'normal',
          letterSpacing: '0px',
          lineHeight: '1.25rem',
          textDecoration: 'underline',
        },
      },
      selectFieldLarge: {
        label: {
          fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
          fontSize: '1.5rem',
          fontWeight: 500,
          letterSpacing: '0px',
          lineHeight: '1.75rem',
        },
        hint: {
          fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
          fontSize: '1.125rem',
          fontWeight: 'normal',
          letterSpacing: '0px',
          lineHeight: '1.75rem',
        },
        moreInfo: {
          fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
          fontSize: '1.5rem',
          fontWeight: 500,
          letterSpacing: '0px',
          lineHeight: '1.75rem',
        },
      },
    },
  },
  shape: {
    borderRadius: 0,
  },
  palette: {
    common: {
      white: '#FFFFFF',
      black: '#000000',
    },
    primary: {
      main: '#75E462',
      dark: '#292929',
      light: 'C5C4BE',
    },
    secondary: {
      main: '#FFF',
    },
    background: {
      default: '#FFFFFF',
    },
    error: {
      main: '#FC005E',
      light: '',
    },
    green: {
      main: '#75E462',
      [50]: '#EDFCEA',
      [100]: '#D3F6AA',
      [200]: '#B4F0A7',
      [300]: '#93EA81',
      [400]: '#75E462',
      [500]: '#55DE43',
      [600]: '#44CD3a',
      [700]: '#24B730',
      [800]: '#00A325',
      [900]: '#007F15',
    },
    dark: {
      main: '#292929',
      [50]: '#B1B0Ab',
      [100]: '#9E9D99',
      [200]: '#8A8986',
      [300]: '#767673',
      [400]: '#626260',
      [500]: '#4F4F4E',
      [600]: '#3B3B3B',
      [700]: '#292929',
      [800]: '#1A1A1A',
      [900]: '#0A0A0A',
    },
    light: {
      main: '#C5C4BE',
      [50]: '#FCFBF6',
      [100]: '#F7F5F0',
      [200]: '#F3F1EC',
      [300]: '#ECEBE6',
      [400]: '#E5E4DF',
      [500]: '#DFDED9',
      [600]: '#D9D8D2',
      [700]: '#D2D1CB',
      [800]: '#CCCBC5',
      [900]: '#C5C4BE',
    },
    utility: {
      amberLight: '#E4B462',
      amberDark: '#CD963A',
      redLight: '#E46262',
      redDark: '#CD3A3A',
      purpleLight: '#6A62E4',
      purpleDark: '#6A3ACD',
      greenHover: '#D3F6CA', // Button components
      btnDisabledLightBg: '#EBE9E5',
      btnDisabledDarkBg: '#353535',
      linkPrimaryUnderlineEnabled: '#95C99E',
      linkFocusBg: '#CCF2C6',
      linkDisabledText: '#A2A19E',
      linkDisabledUnderline: '#C9C8C5',
      linkSecondaryUnderlineEnabled: '#535352',
      noticeError: '#FBEFEB',
      modalFooter: '#EBE9E5',
    },
  },
  shadows: [
    'none', // 0
    '0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12), 0px 1px 3px 0px rgba(0, 0, 0, 0.2)', // 1
    '0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12), 0px 1px 5px 0px rgba(0, 0, 0, 0.2)', // 2
    '0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 3px 3px -2px rgba(0, 0, 0, 0.12), 0px 1px 8px 0px rgba(0, 0, 0, 0.2)', // 3
    '0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12), 0px 2px 4px -1px rgba(0, 0, 0, 0.2)', // 4
    '0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12), 0px 3px 5px -1px rgba(0, 0, 0, 0.2)', // 5
    '0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12), 0px 5px 5px -3px rgba(0, 0, 0, 0.2)', // 6
    '0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12), 0px 5px 6px -3px rgba(0, 0, 0, 0.2)', // 7
    '0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12), 0px 7px 8px -4px rgba(0, 0, 0, 0.2)', // 8
    '0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12), 0px 8px 10px -5px rgba(0, 0, 0, 0.2)', // 9
    '0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 11px 15px -7px rgba(0, 0, 0, 0.2)', // 10
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
  ],
  layouts: {
    stepGrid: {
      gridMainContainer: {
        maxWidth: '1280px',
        margin: 'auto',
        padding: '1rem',
      },
      gridMain: {
        padding: '1rem',
      },
    },
  },
  bodyStyles: {
    body1: {
      fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
      '@media (max-width: 767px)': {
        fontSize: '1.2rem', // 16px
        lineHeight: '1.625rem', // 26px
      },
      fontSize: '1.2rem', // 18px
      lineHeight: '1.75rem', // 28px
      letterSpacing: '0px',
      
    },
    body2: {
      fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
      '@media (max-width: 767px)': {
        fontSize: '0.875rem', // 14px
        lineHeight: '1.375rem', // 22px
      },
      fontSize: '1rem', // 16px
      lineHeight: '1.625rem', // 26px
      fontWeight: 'normal',
      letterSpacing: '0px',
    },
  },
});

export default theme;
