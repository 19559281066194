import React from 'react';
import { useStyles } from './YourCoverReceiptStyles';
import { Box } from '@material-ui/core';
import { Typography } from '..';
import { format } from 'date-fns';
import { convertDateToDlgFormat } from '../../../utils/dateFormattingUtils';
import clsx from 'clsx';

interface IYourCoverReceipt {
  startDate: Date | string | null | undefined;
  coverType: string;
  vehicleCount: number | null;
}
export const YourCoverReceipt: React.FC<IYourCoverReceipt> = ({
  startDate,
  coverType,
  vehicleCount,
}: IYourCoverReceipt): JSX.Element => {
  const classes = useStyles();

  const formattedPolicyStartDate = startDate ? convertDateToDlgFormat(startDate.toString()) : null;

  const policyStartDate = formattedPolicyStartDate ? format(new Date(formattedPolicyStartDate), 'do MMMM, yyyy') : '';

  return (
    <>
      <Box className={classes.yourCoverReceiptContainer}>
        <Box className={classes.yourCoverReceiptInnerContainer}>
          <Box className={classes.yourCoverReceiptDataContainer}>
            <Box className={classes.yourCoverReceiptLabelContainer}>
              <Typography className={clsx(classes.policyLabel, 'fw-500')} variant="body1">
                Policy start date:
              </Typography>
            </Box>
            <Box>
              <Typography className={classes.yourCoverReceiptLabelContainer}> 00:01am, {policyStartDate}</Typography>
            </Box>
          </Box>

          <Box className={classes.yourCoverReceiptDataContainer}>
            <Box className={classes.yourCoverReceiptLabelContainer}>
              <Typography className={clsx(classes.policyLabel, 'fw-500')} variant="body1">
                Cover level:
              </Typography>
            </Box>
            <Box>
              <Typography className={classes.yourCoverReceiptLabelContainer}>{coverType}</Typography>
            </Box>
          </Box>
          <Box className={classes.yourCoverReceiptDataContainer}>
            <Box className={classes.yourCoverReceiptLabelContainer}>
              <Typography className={clsx(classes.policyLabel, 'fw-500')} variant="body1">
                Vehicles covered:
              </Typography>
            </Box>
            <Box>
              <Typography className={classes.yourCoverReceiptLabelContainer}>{vehicleCount}</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default YourCoverReceipt;
