import { Radio as RadioBtn, withStyles } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      display: 'flex',
      alignItems: 'flex-start',
      height: '4.5rem',
      width: '24rem',
      marginLeft: 0,
      marginTop: '1rem',
      marginBottom: '0.6rem',
      padding: '0.6rem 1.2rem 0.5rem 1.2rem',
      border: `2px solid ${theme.palette.dark[400]}`,
      '&:active': {
        border: `2px solid ${theme.palette.dark[400]}`,
        backgroundColor: theme.palette.light[100],
      },
      '& span': {
        ...theme.bodyStyles.body2,
        display: 'flex',
        flexDirection: 'column',
      },
      '& span:first-child': {
        ...theme.leads.lead1,
        letterSpacing: '0px',
        lineHeight: '2.8rem',
      },
      '@media (max-width: 600px)': {
        '& span:first-child': {
          ...theme.leads.lead2,
        },
        '& span': {
          ...theme.bodyStyles.body2,
          paddingTop: '0.15rem',
        },
      },
      '@media (max-width: 500px)': {
        width: '100%',
        height: '3.5rem',
        maxWidth: '20rem',
        minHeight: '3.5rem',
      },
    },
    inputLabel: {
      marginBottom: '0.4rem',
    },
    unselectedRadioBtn: {
      backgroundColor: theme.palette.light[100],
    },
    selectedRadioBtn: {
      border: `2px solid ${theme.palette.green[500]}`,
      backgroundColor: theme.palette.green[50],
    },
    selectedRadioBtnMainText: {
      fontWeight: 500,
    },
    selectedRadioBtnSecondaryText: {
      margin: 0,
    },
  }),
  { index: 1 },
);

export const Radio = withStyles((theme) => ({
  root: {
    color: theme.palette.common.black,
    '&$checked': {
      color: theme.palette.green[800],
    },
  },
  checked: {},
}))(RadioBtn);
