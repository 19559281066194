import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: theme.palette.common.white,
      maxWidth: '777px',

      zIndex: 999,
      '@media (max-width: 767px)': {
        width: '100%',
      },
    },
    paper: {
      backgroundColor: theme.palette.common.white,
      padding: theme.spacing(2, 4, 3),
      '@media (min-width: 767px)': {
        minWidth: '35rem',
      },
    },
    iconButton: {
      color: theme.palette.common.black,
      float: 'right',
      marginLeft: 'auto',
      '&:hover': {
        cursor: 'pointer',
        color: theme.palette.error.main,
      },
    },
    closeIcon: {
      fontSize: '3rem',
    },
    closeIconSmall: {
      fontSize: '1.5rem',
    },
    borderStyle: {
      borderTop: '10px solid',
      borderTopColor: theme.palette.green.main,
    },
  }),
);
