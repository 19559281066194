import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  ...theme.layouts.stepGrid,

  root: {
    paddingTop: '2rem',
    width: '100%',
    backgroundColor: theme.palette.common.white,
  },
  gridMainContainer: {
    padding: '1rem 1rem 0 1rem',
    margin: 'auto',
    maxWidth: '1280px',
  },
  gridMain: {
    padding: '1rem 1rem 0 1rem',
  },
}));
