import React, { useContext, useEffect } from 'react';
import { useStyles } from './ErrorStyles';
import { DocumentTitle, Typography, ButtonPrimary } from '../../components/atoms';
import PageTemplate from '../../templates/PageTemplate';
import { StepContext } from '../../contexts/StepContext';
import { Grid } from '@material-ui/core';
import timeoutClock from '../../assets/img/timeout/device_access_time.svg';

export const Timeout: React.FC = () => {
    const { updateShowStepper } = useContext(StepContext);

    const classes = useStyles();

    useEffect(() => {
        window.scrollTo(0, 0);
        updateShowStepper(false);
    }, []);

    return (
        <PageTemplate>
            <DocumentTitle title={`DLG ${process.env.REACT_APP_SITE_ID} - Timeout`} />
            <Grid container className={classes.gridMainContainer}>
                <Grid item xs={12} sm={5} lg={6} className={classes.gridMain}>
                    <Typography className={classes.heading} variant="h1">
                        YOUR SESSION<br />
                        TIMED OUT
                    </Typography>
                    <Typography className="pt1">As you were inactive for a while, we&apos;ve closed your session to protect your information.</Typography>
                    <Typography className="pt1 pb3">Tap the button below to get another quote for your fleet.</Typography>
                    <ButtonPrimary buttonType="light" href="/">Get a new quote</ButtonPrimary>
                </Grid>
                <Grid item sm={7} lg={6} className={classes.gridImageContent}>
                    <img src={timeoutClock} alt="Timeout Clock" className={classes.timeoutClock} />
                </Grid>
            </Grid>
        </PageTemplate>
    );
};

export default Timeout;