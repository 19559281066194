import React from 'react';
import { Box, IconButton } from '@material-ui/core';
import MuiModal from '@material-ui/core/Modal';
import { useStyles } from './RemoveVehicleModalStyles';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import { Typography } from '../Typography';
import { ButtonSecondary } from '../ButtonSecondary';

export interface IRemoveVehicleModal {
  isOpen: boolean;
  onCloseClick: () => void;
  ariaLabelledBy: string;
  testId?: string;
  isSmallClose?: boolean;
  isBorderStyle?: boolean;
  isCloseBtn?: boolean;
  handleRemoveVehicleIndexProp: (index: number) => void;
  index?: any;
}

const RemoveVehicleModal: React.FC<IRemoveVehicleModal> = ({
  isOpen,
  onCloseClick,
  ariaLabelledBy,
  isSmallClose,
  isBorderStyle,
  handleRemoveVehicleIndexProp,
  index,
}: IRemoveVehicleModal): JSX.Element => {
  const classes = useStyles();

  return (
    <MuiModal disablePortal aria-labelledby={ariaLabelledBy} open={isOpen} onClose={onCloseClick}>
      <div>
        <Box
          className={clsx(classes.modal, isBorderStyle ? classes.borderStyle : '')}
          style={{ maxHeight: '100vh', overflow: 'auto' }}
        >
          <Box className="py1">
            <IconButton className={classes.iconButton} aria-label="Close" onClick={onCloseClick}>
              <CloseIcon className={isSmallClose ? classes.closeIconSmall : classes.closeIcon} />
            </IconButton>
          </Box>
          <div className={classes.paper}>
            <Box>
              <Typography variant="h1">ARE YOU SURE?</Typography>
              <Typography className="mt3">This vehicle will be removed and won&apos;t be covered in the event of a breakdown.</Typography>
            </Box>
            <Box className="mt2">
              <ButtonSecondary 
                onClick={(e) => {
                  e.preventDefault();
                  handleRemoveVehicleIndexProp(index);
                  onCloseClick();
                }} 
                aria-label='Remove vehicle button'
                className="mr1"
              >
                YES, REMOVE
              </ButtonSecondary>
              <ButtonSecondary 
                onClick={onCloseClick}
                aria-label='Cancel remove vehicle'
              >
                CANCEL
              </ButtonSecondary>
            </Box>
          </div>
        </Box>
      </div>
    </MuiModal>
  );
};

export default RemoveVehicleModal;