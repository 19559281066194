import React, { Dispatch, SetStateAction, useState } from 'react';
import { useStyles, StyledListItem } from '../PostcodeLookupStyles';
import { Typography, ButtonPrimary, ButtonSecondary } from '../../../atoms';
import { List, ListItemText } from '@material-ui/core';
import { IHopeWiserItem } from '../PostcodeLookup.component';

interface IPostcodeLookupAddressModalBody {
  setShowAddressModal: Dispatch<SetStateAction<boolean>>;
  addresses: Array<IHopeWiserItem>;
  showCompanySelection: (selectedIndex: number, name: string) => Promise<void>;
  name: string;
  postcode: string;
}

export const PostcodeLookupAddressModalBody: React.FC<IPostcodeLookupAddressModalBody> = ({
  setShowAddressModal,
  addresses,
  showCompanySelection,
  name,
  postcode,
}: IPostcodeLookupAddressModalBody): JSX.Element => {
  const [selectedIndex, setSelectedIndex] = useState(1);
  const classes = useStyles();

  const handleCancel = (): void => setShowAddressModal(false);
  const handleListItemClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number) => {
    setSelectedIndex(index);
  };

  return (
    <div className={classes.resultsContainer}>
      <Typography className={classes.information}>Searching for {postcode}</Typography>
      <Typography className={classes.information}>
        {addresses.length === 0 ? 'No match found' : `${addresses.length} possible matches found.`}
      </Typography>
      <List component="nav" aria-label="address list" className={classes.list}>
        {addresses.map((address: IHopeWiserItem, index: number) => (
          <StyledListItem
            key={index}
            button
            selected={selectedIndex === index + 1}
            onClick={(e) => handleListItemClick(e, index + 1)}
            onDoubleClick={() => showCompanySelection(selectedIndex - 1, name)}
          >
            <ListItemText primary={address.ItemText.replace(/,/g, ', ')} />
          </StyledListItem>
        ))}
      </List>
      <div className={classes.actionButtonsContainer}>
        <ButtonPrimary className={classes.responsivePadding} onClick={() => showCompanySelection(selectedIndex - 1, name)}>Select</ButtonPrimary>
        <ButtonSecondary onClick={handleCancel}>Cancel</ButtonSecondary>
      </div>
    </div>
  );
};

export default PostcodeLookupAddressModalBody;
