import React from 'react';
import { useStyles } from './NextStepsStyles';
import { Grid, Container } from '@material-ui/core';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import { Typography } from '../../atoms';
import clsx from 'clsx';

const NextSteps: React.FC = (): JSX.Element => {
  const classes = useStyles();

  return (
    <div>
      <Grid container>
        <Container className={clsx(classes.noPadding, 'pb2')}>
          <div className={classes.nextStepContainer}>
            <Grid item xs={1}>
              <FindInPageIcon className={classes.icons} />
            </Grid>
            <Grid item xs={11} className={classes.responsiveIconPadding}>
              <Typography variant="h3">Make sure you check over your policy documents.</Typography>
              <Typography className="py1" variant="body1">
                Your docs will be sent to you within the next 7-10 days.
              </Typography>
              <Typography variant="body1">
                Once they&apos;ve arrived, make sure you read through them carefully to check everything&apos;s as
                expected.
              </Typography>
            </Grid>
          </div>
        </Container>
      </Grid>
    </div>
  );
};

export default NextSteps;
