import React, { useContext } from 'react';
import { Box, Grid } from '@material-ui/core';
import { Typography } from '../Typography';
import { ButtonSecondary } from '../ButtonSecondary';
import { useStyles } from './BusinessDetailsStyles';
import { IStepData, Step, StepContext, steps } from '../../../contexts/StepContext';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';

interface IBusinessDetailsComponent {
  data: IStepData;
}

const BusinessDetails: React.FC<IBusinessDetailsComponent> = ({
  data,
}: IBusinessDetailsComponent) => {
  const { activeStep, updateActiveStep } = useContext(StepContext);
  const classes = useStyles();
  const history = useHistory();

  const handleEditContactDetails = () => {
    updateActiveStep(activeStep - 2);
    history.push(steps[Step.BUSINESS_DETAILS].url);
  };

  return (
    <Box className={classes.businessDetailsContainer}>
      <Typography variant='h3' className={classes.businessHeadersPadding}>
        Business details
      </Typography>
      <Grid container>
        <Grid item xs={6} lg={3} md={3}>
          <Typography className={classes.px05}>Business name:</Typography>
        </Grid>
        <Grid item xs={6} lg={6} md={6}>
          <Typography variant="body1" className={clsx(classes.px05, classes.fw500)}>
            {data.businessCompanyName}
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={6} lg={3} md={3}>
          <Typography className={classes.px05}>Address:</Typography>
        </Grid>
        <Grid item xs={6} lg={6} md={6}>
          <Grid item xs={12}>
            <Typography variant="body1" className={clsx(classes.px05, classes.fw500)}>
              {data.addressLine1}
              {data.addressLine2 !== '' ? `, ${data.addressLine2} ` : ''}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              {data.addressLine3 !== '' ? `${data.addressLine3}, ` : ''}
              {data.addressLine4 !== '' ? `${data.addressLine4}, ` : ''}
              {data.addressLine5 !== '' ? `${data.addressLine5}, ` : ''} {data.postcode}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <ButtonSecondary className="mt1" onClick={handleEditContactDetails}>
        Edit business details
      </ButtonSecondary>

      <Typography variant='h3' className={classes.businessHeadersPadding}>
        Business point of contact
      </Typography>

      <Grid container>
        <Grid item xs={6} lg={3} md={3}>
          <Typography className={classes.px05}>Name:</Typography>
        </Grid>
        <Grid item xs={6} lg={6} md={6}>
          <Typography variant="body1" className={clsx(classes.px05, classes.fw500)}>
            {data.businessContactName} {data.businessContactSurname}
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={6} lg={3} md={3}>
          <Typography className={classes.px05}>Contact number:</Typography>
        </Grid>
        <Grid item xs={6} lg={6} md={6}>
          <Typography variant="body1" className={clsx(classes.px05, classes.fw500)}>
            {data.businessContactNumber}
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={6} lg={3} md={3}>
          <Typography className={classes.px05}>Email address:</Typography>
        </Grid>
        <Grid item xs={6} lg={6} md={6}>
          <Typography variant="body1" className={clsx(classes.px05, classes.fw500)}>
            {data.businessContactEmail}
          </Typography>
        </Grid>
      </Grid>

      {data.secondPointOfContact ? (
        <>
          <Typography variant='h2' className="mt2">
            Second point of contact
          </Typography>
          <Grid container>
            <Grid item xs={6} lg={3} md={3}>
              <Typography className={classes.px05}>Name:</Typography>
            </Grid>
            <Grid item xs={6} lg={6} md={6}>
              <Typography variant="body1" className={clsx(classes.px05, classes.fw500)}>
                {data.secondBusinessContactName} {data.secondBusinessContactSurname}
              </Typography>
            </Grid>
          </Grid>
        </>
      ) : (
        <></>
      )}

      <ButtonSecondary className="mt1" onClick={handleEditContactDetails}>
        Edit contact details
      </ButtonSecondary>
    </Box>
  );
};

export default BusinessDetails;
