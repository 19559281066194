import { makeStyles, Theme } from '@material-ui/core/styles';
import roadMarking from '../../../assets/img/roadMarkings/Road Markings@1x.png';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    yourCoverContainer: {
      maxWidth: '44rem',
      marginBottom: '3rem',
      '& h3': {
        marginBottom: '2rem',
      },
    },
    yourCoverGridItem: {
      display: 'flex',
      marginBottom: '1.5rem',
      '&:nth-last-child(2)': {
        marginBottom: 0,
      },
      '&:last-child': {
        marginBottom: 0,
      },
      '@media (max-width: 600px)': {
        '&:nth-last-child(2)': {
          marginBottom: '1.5rem',
        },
      },
    },
    checkIcon: {
      color: theme.palette.green[800],
      marginRight: '0.5rem',
    },
    infoIconButton: {
      marginLeft: 'auto',
      padding: 0,
      display: 'flex',
      alignSelf: 'flex-start',
    },
    infoIcon: {
      color: theme.palette.grey[600],
    },
    infoIconHighlight: {
      color: theme.palette.green[700],
    },
    secondGridColumn: {
      marginLeft: '3rem',
      '@media (max-width: 599px)': {
        marginLeft: 0,
      },
    },
    coverKeyPoint: {
      paddingRight: '1rem',
    },
    yourCoverBackground: {
      border: `2px solid ${theme.palette.light[400]}`,
      borderRadius: '4px',
      padding: '2.5rem 2.5rem 2.5rem 2.5rem',
      '@media (max-width: 765px)': {
        padding: '1.5rem',
      },
    },
    yourCoverSummaryBackground: {
      padding: '2rem 3rem 2rem 3rem',
      backgroundImage: `url(${roadMarking})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'calc(100% - 13%) calc(100% - 10px)',
      backgroundColor: theme.palette.primary.dark,
      '@media (max-width: 949px)': {
        backgroundPosition: 'calc(100% - 10%) calc(100% - 60px)',
      },
      '@media (max-width: 767px)': {
        backgroundPosition: 'calc(100% - 10%) calc(100% - 32px)',
        padding: '2rem 2.5rem 2rem 2.5rem',
      },
      '@media (max-width: 453px)': {
        backgroundPosition: 'calc(100% - 10%) calc(100% - 88px)',
      },
      '& h5': {
        color: theme.palette.common.white,
        marginBottom: '0.75rem',
        lineHeight: '2.188rem',
        ...theme.typography.h5,
        fontWeight: 500,
        '&:last-child': {
          marginBottom: 0,
        },
      },
    },
    greenHighlight: {
      color: theme.palette.green.main,
    },
    headings: {
      fontSize: '2rem', // 32px
      lineHeight: '2.188rem', // 35px
      color: theme.palette.light[50],
    },
  }),
  { index: 1 },
);
