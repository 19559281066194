import React from 'react';
import { useStyles } from './NavStepperStyles';
import { Typography } from '../';
import { StepContext } from '../../../contexts/StepContext';
import { Box, Grid } from '@material-ui/core';


export const NavStepper: React.FC = (): JSX.Element => {
  const classes = useStyles();
  const context = React.useContext(StepContext);
  const { activeStep, steps } = context;
  const filteredActualNumberOfStep = steps.filter((element) => element.showStepCountLabel == null || element.showStepCountLabel == undefined);
  
  return (
    <Box className={classes.root}>
      <Grid container className={classes.gridMainContainer}>
        <Grid item xs={12} lg={8} className={classes.gridMain}>
          <Typography>
            Step {activeStep} of {filteredActualNumberOfStep.length}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default NavStepper;
