import React, { useContext, useEffect, useState } from 'react';
import { useStyles } from './VehicleLookUpStyles';
import { Typography } from '@material-ui/core';
import { ButtonSecondary, RemoveVehicleModal } from '../../atoms';
import clsx from 'clsx';
import { VehicleData, StepContext } from '../../../contexts/StepContext';
import VehicleLookUpUnit from './VehicleLookupUnit/VehicleLookUpUnit.component';
import VehicleLookUpDisplay from './VehicleLookupDisplay/VehicleLookUpDisplay.component';
import { calculateCostTotal } from '../../../utils/yourQuoteUtils';

interface Props {
  vehicleCount: any;
  vehicleDetails: VehicleData[];
  control?: any;
  trigger?: any;
  showErrors: boolean;
  vehicleDetailsLookup: (data: VehicleData[]) => void;
}

interface ErrorMessage {
  vehicle: number | null;
  visible: boolean;
  errorMessage: string | null;
}

const VehicleLookUp: React.FC<Props> = ({
  vehicleCount,
  vehicleDetails,
  vehicleDetailsLookup,
  control,
  showErrors,
}: Props): JSX.Element => {
  const classes = useStyles();
  const { updateData, data } = useContext(StepContext);
  const [ removeVehicleModalOpen, setRemoveVehicleModalOpen ] = useState(false);
  const [ removeIndex, setRemoveIndex ] = useState(0); 
  // STATE VARS

  // Handle error bubbles
  const [showErrorMessage, setShowErrorMessage] = useState<ErrorMessage>({
    vehicle: null,
    visible: false,
    errorMessage: null,
  });

  // Track an error state within this parent component
  // not currently using this, but we are calculating it
  const [hasErrors, setHasErrors] = useState(false);

  // State to hold the array of input values for each vehicle
  const [inputValues, setInputValues] = useState(Array.from({ length: vehicleCount }, () => ''));

  // State to hold the vehicle data for each vehicle
  const [vehicleData, setVehicleData] = useState<VehicleData[]>(
    Array.from({ length: vehicleCount }, () => ({} as VehicleData)),
  );

  // Holds the registration inputs as an array of strings?
  const [registrationInputs, setRegistrationInputs] = useState(Array.from({ length: vehicleCount }, () => ''));

  useEffect(() => {
    if (vehicleDetails && vehicleDetails.length > 0) {
      setVehicleData(vehicleDetails);
    }
  }, [vehicleDetails]);

  //TODO: the updating of data that occcurs during add/remove/showDisplay could be broken out into a reusable function

  // Add blank item to the vehicle data array, update the page state
  const handleAddInputField = () => {
    const blankVehicle: VehicleData = {
      reactIndex: vehicleData.length + 1,
      reg: '',
      make: '',
      model: '',
      year: null,
      complete: false,
    };

    setInputValues([...inputValues, '']);
    setRegistrationInputs([...registrationInputs, '']);
    setVehicleData([...vehicleData, blankVehicle]);
    vehicleDetailsLookup([...vehicleData, blankVehicle]); // update page data

    const totalQuote = calculateCostTotal(data.coverOptions, data.coverType, data.vehicleCount + 1);

    updateData({
      ...data,
      vehicleCount: data.vehicleCount + 1,
      quoteTotal: totalQuote,
    });
  };

  // Remove a vehicle input and nix the item in the array, update the page state
  const handleRemoveVehicle = (index: number) => {
    if (vehicleData.length === 1) {
      return;
    }

    const newInputValues = [...inputValues];
    newInputValues.splice(index, 1);

    const newRegistrationInputs = [...registrationInputs];
    newRegistrationInputs.splice(index, 1);

    const newVehicleData = [...vehicleData];
    newVehicleData.splice(index, 1);

    setInputValues(newInputValues);
    setRegistrationInputs(newRegistrationInputs);
    setVehicleData(newVehicleData);
    vehicleDetailsLookup(newVehicleData); // update page data

    const totalQuote = calculateCostTotal(data.coverOptions, data.coverType, data.vehicleCount - 1);

    updateData({
      ...data,
      vehicleCount: data.vehicleCount - 1,
      quoteTotal: totalQuote,
    });
  };

  // Reset this item in the array and update the state and page state also
  const handleShowVehicleLookup = (index: number) => {
    const blankVehicle: VehicleData = {
      reactIndex: index,
      reg: '',
      make: '',
      model: '',
      year: null,
      complete: false,
    };

    const newVehicleData = [...vehicleData];
    newVehicleData.splice(index, 1, blankVehicle);

    setVehicleData(newVehicleData);
    vehicleDetailsLookup(newVehicleData); // update page data
  };

  // Look through the vehicleData and filter all items that aren't complete
  // if the array length is longer than 0, we have incomplete items and may need to handle this
  // Currently, we're setting the value but not using it as we're checking the state
  // at page level to track this, so this may not be needed
  const handleErrorState = (errorState: ErrorMessage): void => {
    const foundErrors = vehicleData.filter((vehicle) => !vehicle.complete);
    setHasErrors(foundErrors.length > 0);
    setShowErrorMessage(errorState);
  };

  const isDisabled = (index): boolean => {
    if (index == 0) {
      return false; // item is enabled
    }

    for (let i = 0; i < index; i++) {
      if (!vehicleData[i].complete) {
        return true; // item is disabled
      }
    }

    return false; // item is enabled
  };

  return (
    <>
      <div>
        {inputValues.map((value, index) => (
          <div key={index}>
            <Typography className={classes.regNumberTitle} variant="h2">
              Vehicle {index + 1}
            </Typography>
            {!vehicleData[index].reg && (
              <>
                <VehicleLookUpUnit
                  registrationInputs={registrationInputs}
                  setRegistrationInputs={setRegistrationInputs}
                  vehicleIndex={index}
                  setVehicleData={setVehicleData}
                  setShowErrorMessage={handleErrorState}
                  vehicleDetailsLookup={vehicleDetailsLookup}
                  control={control}
                  isDisabled={isDisabled(index)}
                  vehicleData={vehicleData}
                  showError={showErrors && !vehicleData[index].complete}
                />
              </>
            )}

            {vehicleData[index].reg && (
              <>
                <VehicleLookUpDisplay
                  index={index}
                  vehicle={vehicleData[index]}
                  handleShowVehicleLookup={handleShowVehicleLookup}
                />
              </>
            )}
            {vehicleData.length > 1 && (
              <ButtonSecondary
                className={clsx(classes.inputWidth, 'mb2 mt1')}
                onClick={() => {
                  setRemoveIndex(index);
                  setRemoveVehicleModalOpen(true); 
              }}
              >
                Remove vehicle
              </ButtonSecondary>
            )}
          </div>
        ))}
        {removeVehicleModalOpen === true && (
          <RemoveVehicleModal 
            isOpen={removeVehicleModalOpen}
            onCloseClick={() => setRemoveVehicleModalOpen(false)}
            ariaLabelledBy="Remove vehicle modal"
            isCloseBtn={false}
            handleRemoveVehicleIndexProp={handleRemoveVehicle}
            index={removeIndex}
          />
        )}
        {/* Should only display up to 49 vehicles in index */}
        {vehicleData.length < 50 && (
          <ButtonSecondary className="mt1" onClick={handleAddInputField}>
            Add a vehicle
          </ButtonSecondary>
        )}
      </div>
    </>
  );
};

export default VehicleLookUp;
