import React, { useContext } from 'react';
import { Box, Grid } from '@material-ui/core';
import { Typography } from '../Typography';
import { ButtonSecondary } from '../ButtonSecondary';
import { useStyles } from './YourVehiclesReviewStyles';
import { IStepData, VehicleData, Step, StepContext, steps } from '../../../contexts/StepContext';
import { useHistory } from 'react-router-dom';

interface IYourVehiclesReviewComponent {
  data: IStepData;
}

const YourVehiclesReview: React.FC<IYourVehiclesReviewComponent> = ({ data }: IYourVehiclesReviewComponent) => {
  const { activeStep, updateActiveStep } = useContext(StepContext);
  const classes = useStyles();
  const history = useHistory();

  const handleEditVehicles = () => {
    updateActiveStep(activeStep - 3);
    history.push(steps[Step.VEHICLE_DETAILS].url);
  };

  return (
    <Box>
      <Typography variant={'h2'}>Your Vehicle(s)</Typography>

      {data.vehicles.map((v: VehicleData, index: number) => (
        <Grid container className="py1" key={index}>
          <Grid item xs={6} lg={3} md={3}>
            <Typography>Vehicle {index +1}:</Typography>
          </Grid>
          <Grid item xs={6} lg={6} md={3}>
            <Typography variant="body1" className={classes.fw500}>{v.reg.toUpperCase()}</Typography>

            <Typography variant="body1">
              {v.make !== '' ? `${v.make}` : ''} {v.model === null ? '' : '' || v.model !== '' ? `, ${v.model}` : ''} 
            </Typography>
          </Grid>
        </Grid>
      ))}
      <Box>
        <ButtonSecondary onClick={handleEditVehicles}>Edit vehicles</ButtonSecondary>
      </Box>
    </Box>
  );
};

export default YourVehiclesReview;
