import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  nextStepContainer: {
    display: 'flex',
    margin: '1rem',
    '@media (max-width: 767px)': {
      margin: '2rem 0rem',
    },
  },
  icons: {
    color: theme.palette.green[800],
    fontSize: '2rem',
    '@media (min-width: 767px)': {
      fontSize: '3.5rem',
    },
  },

  responsiveIconPadding: {
    paddingLeft: '2rem',
    '@media (max-width: 767px)': {
      paddingLeft: '1rem',
    },
  },

  noPadding: {
    '@media (max-width: 767px)': {
      paddingLeft: '0',
      paddingRight: '0',
    },
  },
}));
