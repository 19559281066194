import React from 'react';
import { VehicleData } from '../../../../contexts/StepContext';
import { useStyles } from '../VehicleLookUpStyles';
import { Box, Grid, Typography } from '@material-ui/core';
import { LinkPrimary } from '../../../atoms';

interface Props {
  index: number;
  vehicle: VehicleData;
  handleShowVehicleLookup: (index: number) => void;
}

const VehicleLookUpDisplay: React.FC<Props> = ({ index, vehicle, handleShowVehicleLookup }: Props): JSX.Element => {
  const classes = useStyles();

  return (
    <>
      <Typography className={classes.regNumberTitle} variant="body1">
        Registration number
      </Typography>
      <Box className={classes.vehicleLookUpContainer}>
        <Grid container className={classes.vehicleLookUpGrid}>
          <Grid className={classes.regBoxContainer} item xs={12} sm={3}>
            <div className={classes.regBox}>
              <Typography className="textCenter" variant="h3">
                {vehicle.reg}
              </Typography>
            </div>
          </Grid>
          <Grid className={classes.responsivePadding} item xs={12} sm={9}>
            <Typography variant="body1" className="fw-500">
              {vehicle.make} {vehicle.model}
            </Typography>
            <Typography variant="body2">
              {vehicle.year}
            </Typography>
          </Grid>
        </Grid>
        <Box className={classes.bottomHighlight}>
          <LinkPrimary
            onClick={(e) => {
              e.preventDefault();
              handleShowVehicleLookup(index);
            }}
          >
            Not the right vehicle?
          </LinkPrimary>
        </Box>
      </Box>
    </>
  );
};

export default VehicleLookUpDisplay;
