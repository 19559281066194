import React, { useState } from 'react';
import axios from 'axios';
import { useStyles } from '../VehicleLookUpStyles';
import { Box, Typography } from '@material-ui/core';
import { ButtonPrimary, TextField } from '../../../atoms';
import { VehicleData } from '../../../../contexts/StepContext';

interface Props {
  vehicleIndex: number;
  registrationInputs: string[];
  setRegistrationInputs: React.Dispatch<React.SetStateAction<string[]>>;
  setVehicleData: React.Dispatch<React.SetStateAction<VehicleData[]>>;
  control?: any;
  isDisabled: boolean;
  setShowErrorMessage: (data: { vehicle: number | null; visible: boolean; errorMessage: string | null }) => void;
  vehicleDetailsLookup: (data: VehicleData[]) => void;
  vehicleData: any;
  showError: boolean;
}

const VehicleLookUpUnit: React.FC<Props> = ({
  vehicleIndex,
  registrationInputs,
  setRegistrationInputs,
  setVehicleData,
  setShowErrorMessage,
  vehicleDetailsLookup,
  control,
  isDisabled,
  vehicleData,
  showError,
}: Props): JSX.Element => {
  const classes = useStyles();

  // PROP VARS
  const vehicleInput = registrationInputs[vehicleIndex];

  // STATE VARS
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleOnKeyPress = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    e.key === 'Enter' && e.preventDefault();
  };

  const handleOnChange = (e): void => {
    const newRegistrationInputs = [...registrationInputs];
    newRegistrationInputs[vehicleIndex] = e.target.value.toUpperCase();
    setRegistrationInputs(newRegistrationInputs);
  };

  const handleErrorMessage = (showMessage: boolean, message: string): void => {
    setErrorMessage(message); // internal state
    setShowErrorMessage({
      vehicle: vehicleIndex,
      visible: showMessage,
      errorMessage: message,
    }); // Update parent component
  };

  const handleVLookupSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    const duplicateReg = (reg) => reg.reg === vehicleInput.replace(/ /gi, '').trim();

    if ((typeof vehicleInput === 'string' && vehicleInput.length === 0) || vehicleInput === null || /[^0-9a-z\s]/gi.test(vehicleInput)) {
      // Check for null or empty string value
      handleErrorMessage(true, 'Please enter a valid vehicle registration.');
      return false;
    } else if (vehicleData.some(duplicateReg)) {
      // Reg is duplicate
      handleErrorMessage(true, 'Duplicate registration detected, please enter a unique licence plate.');
      return false;
    } else {
      // No error found yet, clear errors and continue loading
      handleErrorMessage(false, '');
      setLoading(true);

      try {
        const postVrm = vehicleInput
          .replace(/[^0-9a-z\s]/gi, '')
          .replace(/ /g, '')
          .trim();

        const postUrl = `${process.env.REACT_APP_SERVERLESS_BASE_URL}/${process.env.REACT_APP_VEHICLE_LOOKUP_URL}`;
        const postBody = { vrm: postVrm };

        const response = await axios.post(postUrl, postBody);
        const responseData: VehicleData = response.data;

        setVehicleData((prevState) => {
          const newVehicleData = [...prevState];
          newVehicleData[vehicleIndex] = {
            ...responseData,
            reg: vehicleInput,
            complete: true,
            reactIndex: vehicleIndex,
          };
          vehicleDetailsLookup(newVehicleData);
          return newVehicleData;
        });

        handleErrorMessage(false, '');
      } catch (error) {
        setLoading(false);
        handleErrorMessage(true, 'Vehicle not found, please enter a valid registration.');
      }
    }
  };

  if (
    (!isDisabled &&
      showError &&
      (!vehicleData.complete || (typeof vehicleInput === 'string' && vehicleInput.length === 0))) ||
    vehicleInput === null
  ) {
    if (errorMessage == '') {
      setErrorMessage('Please enter a valid vehicle registration.');
    }
  }

  return (
    <>
      <Typography className={classes.regNumberTitle} variant="body1">
        Registration number
      </Typography>
      <Box className={classes.lookupContainer}>
        <TextField
          name="vehicleLookup"
          placeholder="YOUR REG"
          id={`registrationNumber${vehicleIndex}`}
          className={classes.inputWidth}
          type="text"
          value={registrationInputs[vehicleIndex]}
          onChange={handleOnChange}
          onKeyPress={handleOnKeyPress}
          control={control}
          disabled={isDisabled}
          autoFocus
        />

        <ButtonPrimary
          loading={loading}
          className={classes.lookupBtn}
          onClick={(event) => {
            handleVLookupSubmit(event);
          }}
          disabled={isDisabled}
        >
          Find your vehicle
        </ButtonPrimary>
      </Box>
      {errorMessage !== '' && !loading ? <Typography className="fieldError">{errorMessage}</Typography> : null}
    </>
  );
};

export default VehicleLookUpUnit;
