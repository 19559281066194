import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    ...theme.layouts.stepGrid,
    detailsCorrectHeading: {
      marginBottom: '3rem',
      maxWidth: '35rem',
    },
    actionButton: {
      paddingBottom: '5rem',
      marginTop: '1rem',
      '& button': {
        minWidth: '13rem',
        '@media (min-width: 321px)': {
          minWidth: '18rem',
        },
      },
      '@media (max-width: 767px)': {
        flexDirection: 'column',
        display: 'flex',
        justifyContent: 'center',
        '& button': {
          margin: '1rem 0',
        },
      },
    },
    maxWidth30: {
      maxWidth: '30rem',
    },
    lineHeight: {
      lineHeight: '2rem',
    },
    DateField: {
      maxWidth: '18rem',
      width: '100%',
    },
    ukInfoBox: {
      maxWidth: '24rem',
      padding: '1rem',
      backgroundColor: theme.palette.light[100],
    },
    customDatePicker: {
      backgroundColor: theme.palette.light[100],
      minHeight: '2rem',
      minWidth: '20rem',

      '& .MuiOutlinedInput-root': {
        borderRadius: '2px',
        backgroundColor: theme.palette.light[100],
        '& svg': {
          color: theme.palette.dark[500],
        },
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: `2px solid ${theme.palette.dark[300]}`,
      },
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.common.black,
      },
      '& .MuiOutlinedInput-input': {
        color: theme.palette.dark[800],
        padding: '16px 14px',
      },
      '& .MuiOutlinedInput-root.Mui-focused': {
        backgroundColor: theme.palette.light[100],
      },
      '& .MuiInputBase-root.Mui-disabled': {
        backgroundColor: theme.palette.light[200],
        borderColor: theme.palette.light[900],
      },
      '& div.Mui-error': {
        borderColor: theme.palette.utility.redLight,
        boxShadow: `inset 0 0 0 4px ${theme.palette.utility.redDark}`,
        '& .MuiOutlinedInput-notchedOutline': {
          border: 'none',
          borderColor: `${theme.palette.utility.redLight}`,
        },
      },
      '@media (max-width: 767px)': {
        width: '100%',
        minWidth: '0',
      },
    },
    datePicker: {
      maxWidth: '20rem',
      width: '100%',
    },
    value: {
      backgroundColor: theme.palette.light[50],
    },
    inputFields: {
      maxWidth: '20rem',
      width: '100%',
      minHeight: '3rem',
    },
    modalPText: {
      margin: '1rem 0 1rem 0',
    },
  }),
  { index: 1 },
);
