import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  ...theme.layouts.stepGrid,
  px05: {
    paddingTop: '.5rem',
    paddingBottom: '.5rem',
  },
  textRight: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  fw500: {
    fontWeight: 500,
  },
}));
