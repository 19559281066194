import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles(
  (theme: Theme) => ({
    ...theme.layouts.stepGrid,
    gridMainContainer: {
      padding: '.5rem 1rem 1rem 1rem',
      margin: 'auto',
      maxWidth: '1280px',
    },
    reponsiveWidth: {
      '@media (max-width: 1279px)': {
        maxWidth: '100%',
      },
      maxWidth: '70%',
    },
    buttonWidth: {
      minWidth: '8rem !important',
      '@media (min-width: 425px)': {
        minWidth: '8rem !important',
      },
    },
    actionButton: {
      paddingBottom: '3rem',
      marginTop: '1rem',
      '& button': {
        minWidth: '13rem',
        '@media (min-width: 321px)': {
          minWidth: '18rem',
        },
      },
      '@media (max-width: 767px)': {
        flexDirection: 'column',
        display: 'flex',
        justifyContent: 'center',
        '& button': {
          margin: '1rem 0',
        },
      },
    },
    importantStatementsList: {
      '& a': {
        color: theme.palette.dark[800],
      },
    },
    underlineText: {
      textDecorationLine: 'underline',
      cursor: 'pointer',
      color: '#292929'
    },
  }),
  { index: 1 },
);
