import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { STRIPE_BASE } from '../../molecules/Stripe/CardElementStyles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiOutlinedInput-root': {
        borderRadius: '2px',
        backgroundColor: theme.palette.light[100],
        '& svg': {
          color: theme.palette.dark[500],
        },
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: `2px solid ${theme.palette.dark[300]}`,
      },
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.common.black,
      },
      '& .MuiOutlinedInput-input': {
        color: theme.palette.dark[800],
        padding: '16px 14px',
      },
      '& .MuiOutlinedInput-root.Mui-focused': {
        backgroundColor: theme.palette.light[100],
      },
      '& .MuiInputBase-root.Mui-disabled': {
        backgroundColor: theme.palette.light[200],
        borderColor: theme.palette.light[900],
      },
      '& div.Mui-error': {
        borderColor: theme.palette.utility.redLight,
        boxShadow: `inset 0 0 0 4px ${theme.palette.utility.redDark}`,
        '& .MuiOutlinedInput-notchedOutline': {
          border: 'none',
          borderColor: `${theme.palette.utility.redLight}`,
        },
      },
    },
    textFieldEnabled: {
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.dark[300],
      },
    },
    stripe: {
      ['& .' + STRIPE_BASE]: {
        height: '1em',
      },
    },
  }),
);
