import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  ...theme.layouts.stepGrid,

  yourPriceReceiptContainer: {
    marginBottom: '3rem',
    maxWidth: '43rem',
    '& h3': {
      marginBottom: '0.5rem',
    },
  },
  yourPriceReceiptInnerContainer: {
    border: `2px solid ${theme.palette.light[400]}`,
    borderRadius: '4px',
    backgroundColor: theme.palette.common.white,
    padding: '2rem 3rem 2rem 3rem',
    width: '100%',
    '@media (max-width: 765px)': {
      padding: '1.5rem',
    },
  },
  heading: {
    marginBottom: '2rem',
    '@media (max-width: 550px)': {
      marginBottom: '1rem',
      fontSize: '1.125rem',
    },
  },
  px05: {
    paddingTop: '.5rem',
    paddingBottom: '.5rem',
  },
  textRight: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  textSizeSmall: {
    '@media (max-width: 550px)': {
      fontSize: '0.8rem',
    },
  },
}));
