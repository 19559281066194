import React, { useContext, useEffect } from 'react';
import { useStyles } from './AllSortedStyles';
import { DocumentTitle, Typography, YourCoverReceipt, NextSteps } from '../../components/atoms';
import PageTemplate from '../../templates/PageTemplate';
import { StepContext } from '../../contexts/StepContext';
import { Grid, Box } from '@material-ui/core';
import clsx from 'clsx';

export const AllSorted: React.FC = () => {
  const { data, updateShowStepper } = useContext(StepContext);
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
    updateShowStepper(false);

    
    window.gtag('event', 'conversion', {
      allow_custom_scripts: true,
      send_to: 'DC-3535201/gffleet/confleet+standard',
    });
  }, []);

  return (
    <PageTemplate>
      <DocumentTitle title={`DLG ${process.env.REACT_APP_SITE_ID} - All Sorted`} />
      <Box className={classes.bgImage}>
        <Box className={classes.allSortedContainer}>
          <Typography className={classes.allSortedHeading} variant="h1">
            ALL SORTED!
          </Typography>
        </Box>

        <Grid className={classes.gridMainContainer}>
          <Grid item xs={12} lg={12} className={classes.gridMain}>
            <Box margin={'1rem 0'}>
              <Typography className={classes.welcome} variant="h1">
                Here&apos;s all the important info about your cover.
              </Typography>
            </Box>
            <Box display={'flex'} flexDirection={'column'} justifyContent={'center'}>
              <YourCoverReceipt
                startDate={data.coverStartDate}
                coverType={data.coverType}
                vehicleCount={data.vehicleCount}
              />
            </Box>
            <NextSteps />
            <Box className={classes.subHeadingSpacing}>
              <Typography className={clsx(classes.greenExitText, 'mb1')}>RIGHT, THAT&apos;S ENOUGH FROM US.</Typography>
              <Typography className={classes.greenExitText}>GO ENJOY THE REST OF YOUR DAY!</Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </PageTemplate>
  );
};

export default AllSorted;
